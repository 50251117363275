import * as React from 'react';
import cn from 'classnames';
import css from './TextBlock.scss';
import { H6 } from 'styleguide/components/Heading';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  contentTextBlock: React.ReactNode;
  noMargin?: boolean;
  className?: string;
}

const TextBlock = ({ title, contentTextBlock, noMargin, ...props }: Props) => (
  <div className={cn(css.textBlock, props.className)}>
    <H6 className={cn(css.title, { [css[`title--noMargin`]]: !!noMargin })}>{title}</H6>
    <div className="contentWrapper">{contentTextBlock}</div>
  </div>
);

export default TextBlock;
