import * as React from 'react';
import { H4 } from 'app/styleguide/components/Heading';
import FeefoReviews from '../../HomePage/Wow/FeefoReviews';
import FacebookReviews from '../../HomePage/Wow/FacebookReviews';
import GoogleReviews from '../../HomePage/Wow/GoogleReviews';
import YelpReviews from '../../HomePage/Wow/YelpReviews';

const Reviews = () => (
  <>
    <div id="reviews" className="md:-mt-10 md:h-10" />
    <div
      id="reviews-body"
      className="mx-auto max-w-base border-b border-t border-solid border-gray-50 py-16 xs:px-3 md:px-[100px] 3xl:px-0"
    >
      <H4 className="mb-4">Reviews</H4>
      <div className="text-center font-hvMedium text-3xl">
        America&apos;s Highest Rated Online Printing Company
      </div>
      <div className="flex w-full justify-center">
        <FeefoReviews />
      </div>
      <div className="mx-auto sm:grid sm:grid-cols-1 md:hidden md:grid-cols-3 lg:grid lg:w-full lg:max-w-4xl">
        <FacebookReviews showText="none" />
        <GoogleReviews showText="none" />
        <YelpReviews showText="none" />
      </div>
    </div>
  </>
);

export default Reviews;
