import * as React from 'react';
import cn from 'classnames';
import css from './CmsContentSection.scss';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';

interface Props {
  heading?: string | React.ReactNode;
  className?: string;
  bodyClassName?: string;
  content: string;
  id?: string;
}

const CmsContentSection = ({ ...props }: Props) => (
  <div className="border-b border-t border-solid border-gray-50">
    <div
      className={cn('mx-auto max-w-base py-16 xs:px-3 md:px-[100px] 2xl:px-0', props.className)}
      id={`${props.id}-body`}
    >
      {!!props.id && <div id={props.id} className={css.anchorOffset} />}
      {props.heading}
      <HtmlContent content={props.content} className={props.bodyClassName} />
    </div>
  </div>
);

export default CmsContentSection;
