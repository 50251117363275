import * as React from 'react';
import css from './RelatedItems.scss';
import Grid from 'styleguide/components/Grid/Grid';
import RelatedItem from './RelatedItem/RelatedItem';
import { H3 } from 'styleguide/components/Heading';

interface Props {
  title: string;
  children: React.ReactNode;
}

/**
 * TODO: works only for 3 items
 */
const RelatedItems = ({ title, children }: Props) => (
  <Grid.Container className={css.relatedItems}>
    <Grid>
      <Grid.Row>
        <Grid.Col sm={0} md={1} />
        <Grid.Col sm={12} md={10}>
          <H3 className={css.title}>{title}</H3>
        </Grid.Col>
        <Grid.Col sm={0} md={1} />
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={0} md={1} />
        <Grid.Col sm={12} md={10} className={css.col}>
          {children}
        </Grid.Col>
        <Grid.Col sm={0} md={1} />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

RelatedItems.Item = RelatedItem;

export default RelatedItems;
