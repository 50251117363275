import * as React from 'react';
import { HashLink } from 'react-router-hash-link';
import { clickSecondaryNavbar } from 'api/gtm';

interface Props {
  disableFeatures?: boolean;
  disableTemplates?: boolean;
  disableFAQ?: boolean;
  disableBranding?: boolean;
  disableReviews?: boolean;
  disableMoreResources?: boolean;
  disableSEOBlock?: boolean;
  disableArticles?: boolean;
}

const InnerNavigation = ({
  disableFeatures,
  disableTemplates,
  disableFAQ,
  disableBranding,
  disableReviews,
  disableMoreResources,
  disableSEOBlock,
  disableArticles,
}: Props) => (
  <div className="flex h-[78px] w-full items-center py-10" id="innerNav">
    <div className="relative flex w-full flex-wrap justify-around">
      {!disableFeatures && (
        <HashLink
          smooth
          to="#features"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'Product Features' })}
        >
          Product Features
        </HashLink>
      )}
      {!disableBranding && (
        <HashLink
          smooth
          to="#why-printivity"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'Why Printivity' })}
        >
          Why Printivity
        </HashLink>
      )}
      {!disableReviews && (
        <HashLink
          smooth
          to="#reviews"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'Reviews' })}
        >
          Reviews
        </HashLink>
      )}
      {!disableTemplates && (
        <HashLink
          smooth
          to="#templates-and-specs"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'Templates & Specs' })}
        >
          Templates & Specs
        </HashLink>
      )}
      {!disableArticles && (
        <HashLink
          smooth
          to="#articles"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'Articles' })}
        >
          Articles
        </HashLink>
      )}
      {!disableFAQ && (
        <HashLink
          smooth
          to="#faq"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'FAQ' })}
        >
          FAQ
        </HashLink>
      )}
      {!disableMoreResources && (
        <HashLink
          smooth
          to="#more-resources"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'More Resources' })}
        >
          More Resources
        </HashLink>
      )}
      {!disableSEOBlock && (
        <HashLink
          smooth
          to="#more-information"
          className="font-hvMedium text-sm text-default no-underline hover:underline xs:mb-2 md:mb-0"
          onClick={() => clickSecondaryNavbar({ option_selected: 'More Information' })}
        >
          More Information
        </HashLink>
      )}
    </div>
  </div>
);

export default InnerNavigation;
