import * as React from 'react';
import css from './TemplateSpecs.scss';
import Grid from 'styleguide/components/Grid/Grid';
import TextBlock from '../TextBlock/TextBlock';
import Span from 'styleguide/components/Span/Span';
import { A } from 'styleguide/components';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import { trackClickedTemplateLink } from 'api/CmsPages';
import { CmsProductPage, Download } from 'contexts/AppContext/types';
import Link from 'styleguide/components/Links/Link';
import { servicePath } from 'bundles/App/routes';
import { clickTemplate, clickGraphicDesignLink } from 'api/gtm';

interface Props {
  downloads: Download[] | null;
  specs: string | null;
  cmsPage?: CmsProductPage;
}

const TemplateSpecs = ({ downloads, specs, cmsPage }: Props) => (
  <Grid.Container className={css.templateSpecsWrapper}>
    <Grid noPadding>
      <Grid.Row>
        <Grid.Col sm={12} md={5}>
          <TextBlock
            title="Helpful templates"
            contentTextBlock={
              <Span>
                Download a template, drop some artwork on it, and add some text. <br /> Our templates have all
                the necessary information to make you a rockstar designer.
              </Span>
            }
            className="mb-8"
            noMargin
          />
          {!!specs && (
            <TextBlock
              title="Quick specs"
              contentTextBlock={
                <>
                  <Span>Familiar with document design? Here are the basic requirements:</Span>
                  <HtmlContent content={specs} className={css.specContent} />
                </>
              }
            />
          )}
          <br />
          <Span>
            {` Need help designing your file? Our design team is here to help! Get a `}
            <Link
              to={servicePath.designServices}
              color="black"
              underline="always"
              onClick={() => clickGraphicDesignLink({ location: 'pdp_template_section' })}
            >
              graphic design quote
            </Link>{' '}
            {` now! `}.
          </Span>
        </Grid.Col>
        <Grid.Col sm={12} md={7} className={css.downloadsBlockWrapper}>
          {!!downloads && (
            <TextBlock
              className={css.downloadsBlock}
              title="Downloads"
              contentTextBlock={downloads.map((elem, i) => (
                <div
                  className="flex flex-wrap justify-between border-b border-solid border-gray-50 py-4"
                  key={i}
                >
                  <div className="font-hvThin text-base">{elem.text}</div>
                  <div className="flex">
                    {elem.pdfUrl && (
                      <A
                        className="mr-7 block"
                        href={elem.pdfUrl}
                        color="blue"
                        underline="none"
                        noFollow
                        onClick={() => {
                          const ElementInterval = setInterval(() => {
                            trackClickedTemplateLink(cmsPage.id, elem.pdfUrl);
                            clickTemplate({ file: elem.text });
                            clearInterval(ElementInterval);
                          }, 10);
                        }}
                      >
                        PDF
                      </A>
                    )}
                    {elem.psdUrl && (
                      <A
                        className="mr-7 block"
                        href={elem.psdUrl}
                        color="blue"
                        underline="none"
                        noFollow
                        onClick={() => {
                          const ElementInterval = setInterval(() => {
                            trackClickedTemplateLink(cmsPage.id, elem.psdUrl);
                            clickTemplate({ file: elem.text });
                            clearInterval(ElementInterval);
                          }, 10);
                        }}
                      >
                        PSD
                      </A>
                    )}
                    {elem.inddUrl && (
                      <A
                        className="mr-7 block"
                        href={elem.inddUrl}
                        color="blue"
                        underline="none"
                        noFollow
                        onClick={() => {
                          const ElementInterval = setInterval(() => {
                            trackClickedTemplateLink(cmsPage.id, elem.inddUrl);
                            clickTemplate({ file: elem.text });
                            clearInterval(ElementInterval);
                          }, 10);
                        }}
                      >
                        INDD
                      </A>
                    )}
                    {elem.jpgUrl && (
                      <A
                        className="mr-7 block"
                        href={elem.jpgUrl}
                        color="blue"
                        underline="none"
                        noFollow
                        onClick={() => {
                          const ElementInterval = setInterval(() => {
                            trackClickedTemplateLink(cmsPage.id, elem.jpgUrl);
                            clickTemplate({ file: elem.text });
                            clearInterval(ElementInterval);
                          }, 10);
                        }}
                      >
                        JPG
                      </A>
                    )}
                    {elem.allUrl && (
                      <A
                        className="block"
                        href={elem.allUrl}
                        color="blue"
                        underline="none"
                        noFollow
                        onClick={() => {
                          const ElementInterval = setInterval(() => {
                            trackClickedTemplateLink(cmsPage.id, elem.allUrl);
                            clickTemplate({ file: elem.text });
                            clearInterval(ElementInterval);
                          }, 10);
                        }}
                      >
                        ALL
                      </A>
                    )}
                  </div>
                </div>
              ))}
              noMargin
            />
          )}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default TemplateSpecs;
