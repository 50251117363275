import * as React from 'react';
import css from './RelatedItem.scss';
import Image from 'styleguide/components/Image/Image';
import Span from 'styleguide/components/Span/Span';
import Link from 'styleguide/components/Links/Link';

interface Props {
  linkTo: string;
  img: string;
  title: string;
  alt: string;
  subTitle?: string;
  responsiveProportion: number;
}

const RelatedItem = ({ linkTo, title, img, responsiveProportion, subTitle }: Props) => (
  <div className={css.relatedItem}>
    <Link to={linkTo} color="black" underline="none">
      <Image
        title={title}
        alt={title}
        url={img}
        responsive
        responsiveProportion={responsiveProportion}
        className={css.image}
      />
      <Span className={css.title}>{title}</Span>
      {!!subTitle && <Span className={css.subTitle}>{subTitle}</Span>}
    </Link>
  </div>
);

export default RelatedItem;
